h4 {
    width:fit-content;
}

h4:hover{
    color: #808080;
}

.table {
    width:95%;
    margin: auto;
    margin-bottom: 20px;
}