


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


/* ==========
=== FORM  
========== */

/* .form-banner img {
  height: 100%;
  width: 100%;
} */

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-width: 100%;
} 


/* ==========
=== Loader
========== */
.loader-container{
  display:flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
}

.loader{
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: inline-block;
  box-sizing: border-box;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* .img {
  width: 80%;
} */